import Rest from '@/services/Rest';

/**
 * @typedef {TicketStatus}
 */
export default class TicketStatus extends Rest {
    /**
     * @type {String}
     */
    static resource = '/tickets/status'
}
