<template>
    <div style="margin-top: 100px">
        <BasePageBreadCrumb :pages="pages" :title="`${pagination.itens} chamados`" :menu="menu"/>
        <div class="container-fluid qsuite-c-fluid-0">
            <div class="col-12" >
                <div class="row">
                    <div class="col-12">
                        <div class="row" >
                            <div class="col-12 col-md-6 col-lg-8">
                                
                                <div class="form-group d-flex" >
                                    <input type="search" class="form-control campo-procurar" v-model="search" placeholder="Procurar">
                                    <button type="submit" class="btn btn-info" @click="fetchTickets">Buscar</button>
                                </div>
                               
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
                                <div class="form-group d-flex">
                                    <b-link :to="{name: 'SuportNew'}" class=" ml-auto">
                                        <button type="submit" class="btn btn-dark"><i class="far fa-file-alt mr-2"></i>Novo chamado</button>
                                    </b-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-show="isQsuiteAdmin">
                    <div class="col-12 col-md-11 col-lg-9 pb-2">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6 col-md-6 col-lg-auto p-1">
                                    <select v-model="priority_id" class="custom-select mr-sm-2 campo-filter-1" id="inlineFormCustomSelect">
                                        <option :value="null" >Prioridade</option>
                                        <option v-for="item in all_priorities" :value="item.id" :key="item.id">{{item.name}}</option>
                                    
                                    </select>
                                </div>
                                <div class="col-6 col-md-6 col-lg-auto p-1">
                                    <select v-model="status_id"  class="custom-select mr-sm-2 campo-filter-2" id="inlineFormCustomSelect">
                                        <option :value="null">Status</option>
                                        <option v-for="item in all_status" :value="item.id" :key="item.id">{{item.name}}</option>
                                     
                                    </select>
                                </div>
                                <div class="col-6 col-md-6 col-lg-auto p-1">
                                    <select v-model="from_id" class="custom-select mr-sm-2 campo-filter-4" id="inlineFormCustomSelect">
                                        <option :value="null">Procedentes</option>
                                        <option v-for="item in all_from" :value="item.id" :key="item.id">{{item.name}}</option>
                                 
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="card-list-legenda"> 
                        <div class="card-body-list-legend d-none d-lg-block"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-1 align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status">
                                            <p class="title-legend-list">PRIORIDADE</p>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                        <p class="title-legend-list" style="width:45px">&nbsp;</p>
                                        </div>
                                        <div class="col-7 col-md-8 col-lg-9 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <p class="title-legend-list">Empresa</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-3 align-self-center text-muted pad-content-list">
                                                        <p class="title-legend-list">STATUS</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-5 align-self-center pad-content-list">
                                                        <p class="title-legend-list">Assunto</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-list" v-for="item in tickets" :key="item.id"> 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-auto col-lg-1 align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block">
                                            <h6 class="card-body-list-title">{{item.priority ? item.priority.name : 'PEND.'}}</h6>
                                        </div>
                                        <div v-show="false" class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <img src="~@/assets/img/users/widget-table-pic1.jpg" alt="user" class="rounded-circle card-body-list-img">
                                        </div>
                                        <div class="col-7 col-md-6 col-lg-9 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <h6 class="card-body-list-title text-uppercase">{{item.company.name}}</h6>
                                                        <h6 class="font-10 pt-1">Por: {{item.user.first_name}} {{item.user.last_name}}</h6>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-3 align-self-center text-muted pad-content-list">
                                                        <h6 class="status-imovel"><small :class="['badge form-text text-white text-uppercase', item.status.name.split(' ').join('')]">{{item.status.name}}</small></h6>
                                                        <p class="font-10 pt-1 m-0">{{item.created_at}}</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-5 align-self-center pad-content-list">
                                                        <p class="font-10 m-0 text-uppercase"><b>{{item.subject}}</b></p>
                                                        <hr class="my-1">
                                                        <h6 class="whats">{{item.description}}</h6>
                                                        <hr class="my-1">
                                                        <p class="font-10 m-0" v-if="item.prevision_finisher"><b>PREVISÃO DE ENTREGA</b>: {{item.prevision_finisher}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                            <router-link :to="'/suporte/editar/' + item.id" class="btn btn-dark rounded-circle btn-circle font-20 card-body-list-cta">+</router-link>
                                            <a v-if="isQsuiteAdmin" href="javascript:void(0);" @click="deleteTicket(item.id)" class="btn btn-danger text-white rounded-circle btn-circle font-16"><i class="far fa-trash-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   

                </div>
                
                
                <div class="col-12 text-center p-4" v-if="tickets.length == 0">
                    Nenhum chamado registrado.
                </div>
            </div>
            <div class="col-12">
                <BasePagination
                    v-if="products.length > 0"
                    :totalPages="pagination.totalPages"
                    :activePage="pagination.currentPage"
                    @to-page="toPage"
                    @per-page="perPage" />
            </div>
        </div>
    </div>

    </template>
    <script>
    import Ticket from "@/services/resources/Ticket";
    import TicketFrom from  "@/services/resources/TicketFrom";
    import TicketStatus from "@/services/resources/TicketStatus";
    import TicketPriority from "@/services/resources/TicketPriority";

    const serviceFrom = TicketFrom.build();
    const serviceStatus = TicketStatus.build();
    const servicePriority = TicketPriority.build();

    const service = Ticket.build();
    
    export default {
        data(){
            return{
                products: [],
                all_from: [],
               all_status: [],
               all_priorities: [],
                pagination: {
                    totalPages: 1,
                    currentPage: 1,
                    perPage: 10,
                    itens: 0
                },
                tickets: [],
                search: null,
                pages:[
                    {
                        name: 'dashboard',
                        to: 'Dashboard'
                    },
                    {
                        name: 'Chamados',
                        to: ''
                    }
                ],
                menu:[
                    {
                        name: 'Novo chamado',
                        to: 'SuportNew'
                    },
                    {
                        name: 'Chamados',
                        to: 'SuportList'
                    }
                ],
                from_id: null,
                status_id: null,
                priority_id: null
            }
        },
        computed: {
            isQsuiteAdmin() {
               
                return this.$store.getters.isQsuiteAdmin;
            }
        },
        watch: {
            from_id() {
                this.fetchTickets();
            },

            status_id() {
                this.fetchTickets();
            },

            priority_id() {
                this.fetchTickets();
            }

        },
        methods:{
           
            toPage(page) {
                this.pagination.currentPage = page;

            },
            perPage(qtd) {
                this.pagination.currentPage = 1;
                this.pagination.perPage = qtd;

            },
            deleteTicket(id){
    
              this.$bvModal.msgBoxConfirm('Tem certeza que deseja excluir esse chamado?')
              .then(value => {
    
                if(value) {
                    let data = {
                        id: id
                    };
    
                    service
                    .destroy(data)
                    .then(resp => {
                        this.$bvToast.toast('Seu chamado foi excluído com sucesso!', {
                            title: 'Excluir chamado',
                            autoHideDelay: 5000,
                            type: 'success'
                        })
                        this.fetchTickets();
                    })
                    .catch(err => {
                        this.$bvToast.toast('Seu chamado não pode ser excluído.', {
                            title: 'Excluir produto',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }
                   
              })
              .catch(err => {
                // An error occurred
              })
                
    
            },
            fetchPriorities() {
                servicePriority.get('').then(response => {
                    this.all_priorities = response;
                });
            },

            fetchStatus() {
                serviceStatus.get('').then(response => {
                    this.all_status = response;
                });
            },

            fetchFrom() {
                serviceFrom.get('').then(response => {
                    this.all_from = response;
                });
            },
            fetchTickets(){
    
                let data = {
                    page: this.pagination.currentPage,
                    per_page: this.pagination.perPage
                }
    
                if(this.search){
                    data['name_company'] = this.search;
                }

                if(this.status_id) {
                    data['status_id'] = this.status_id;
                }

                if(this.from_id) {
                    data['from_id'] = this.from_id;
                }

                if(this.priority_id) {
                    data['priority_id'] = this.priority_id;
                }
    
                 service
                    .search(data)
                    .then(resp => {
                       
                        let dateFormated = '', hour = '';
                        let date_prevision = '';

                        this.tickets = resp.data.map(item => {
                            hour = item.created_at.split('T')[1].slice(0, 8);
                    
                            dateFormated = item.created_at.split('T')[0].split('-');
                            dateFormated = `${dateFormated[2]}/${dateFormated[1]}/${dateFormated[0]}`;

                       

                            return {
                                ...item,
                                created_at: `${dateFormated} | ${hour}`,
                          
                            }
                        });
                  
                        this.pagination.totalPages = resp.last_page;
                        this.pagination.itens = resp.total;
                    
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        mounted(){
            this.fetchTickets();

            this.fetchFrom();
                this.fetchStatus();
                this.fetchPriorities();
        }
    }
    </script>

    <style scoped>
    .Novo {
        background-color: lightblue;
    }

    .Pendente {
        background-color: crimson;
    }

    .Aberto {
        background-color: lightseagreen
    }

    .Emespera {
        background-color: yellow
    }

    .Resolvido {
        background-color: greenyellow
    }

    .Fechado {
        background-color: black
    }

    </style>