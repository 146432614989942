import Rest from '@/services/Rest';

/**
 * @typedef {TicketPriority}
 */
export default class TicketPriority extends Rest {
    /**
     * @type {String}
     */
    static resource = '/tickets/priorities'
}
